import Component from '@Component';
import GlideSlider from '../base';

// Refer to https://glidejs.com/docs/options/
const glideOptions = {
  type: 'carousel',
  gap: 0,
  autoplay: 4000,
};

const selectors = {
  slideSelector: '[data-photo-slider="slide"]',
  leftArrowSelector: '[data-photo-slider="arrow-left"]',
  rightArrowSelector: '[data-photo-slider="arrow-right"]',
  slideIndicatorSelector: '[data-photo-slider-indicator]',
  slideIndicatorActiveClass: 'section-photo-photos__nav-btn--active',
};

export default class SectionPhotoSlider extends Component {
  static selector = '[data-comp-photo-slider]';

  constructor(containerEl) {
    super(containerEl);
    this.slider = new GlideSlider(containerEl, glideOptions, selectors);
    this.sliderGlideInstance = this.slider.getGlideSlider();

    this.sliderGlideInstance.on(['mount.after', 'run.after'], () => {
      this.slider.adjustSlideWidth(this.slider.getSliderWidth());
      this.slider.handleSlideIndicatorActiveState();
    });

    // const Mutate = function (Glide, Components, Events) {
    //   return {
    //     modify (translate) {
    //       console.log(translate);
    //     },
    //   };
    // };

    // this.sliderGlideInstance.mutate([Mutate]);
    this.sliderGlideInstance.mount();
  }
}
