import { runInView, isValidSelector } from './util';
import { bindComponent, createRegistryEntry } from './registry';

export default class ComponentLoader {
  #idleQueue = [];

  constructor(container, components) {
    this.container = container;

    this.registry = new Map();

    components.forEach(comp => this._registerComponent(comp));
    this._loadAll();
    this._runIdleQueue();
  }

  /**
   * Binds a component to the DOM, and registers the instance
   * @param {Component} Component
   */
  _registerComponent(Component) {
    if (!isValidComponent(Component)) return;
    this.container.querySelectorAll(Component.selector)
      .forEach(element => this._addToRegistry(element, Component));
  }

  _addToRegistry(element, Component) {
    const entry = createRegistryEntry(element, Component);
    this.registry.set(entry.id, entry);
  }

  loadComponent(entry) {
    if (!entry) return;
    const load = getLoader(entry.Component.loaderPriority);
    if (entry.Component.loaderPriority === 'idle') {
      Object.assign(entry, {
        instance: load(entry, this.#idleQueue),
        loaded: 'pending',
      });
    } else {
      Object.assign(entry, {
        instance: load(entry),
        loaded: true,
      });
    }
  }

  _loadAll() {
    [...this.registry.values()].forEach(entry => this.loadComponent(entry));
  }

  _addToQueue(...args) {
    this.#idleQueue.push({ args });
  }

  _runIdleQueue() {
    this.#idleQueue.forEach((entry) => {
      if (!window.requestIdleCallback) {
        window.requestAnimationFrame(() => bindComponent(entry));
      } else {
        window.requestIdleCallback(() => bindComponent(entry), { timeout: 2000 });
      }
    });
  }

  _getComponentsBySelector(selector) {
    return this.registry.get(selector);
  }
}

function isValidComponent(Component) {
  if (!isValidSelector(Component.selector)) {
    console.error(
      `ComponentLoader: The Component subclass, '${Component.name}', needs a valid 'selector' property.`,
      Component,
    );
    return false;
  }
  return true;
}

function getLoader(loader) {
  const loaders = {
    high: bindComponent,
    idle: (entry, queue) => queue.push(entry),
    deley: entry => setTimeout(
      () => bindComponent(entry), entry.Component.priorityDelay,
    ),
    'in-view': entry => runInView(entry.element, () => bindComponent(entry)),
  };
  return loaders[loader];
}
