import Component from '@Component';
import { windowMatchMedia } from '@Constants';

const DEFAULTS = {
  mobileNavTrigger: 'data-mobile-menu-trigger',
  mobileNavActiveClass: 'page-header--active',
  mobileContainer: 'page-header__mobile-container',
  mobileMainPanel: '[data-mobile-panel-content="Main"]',
  subNav: '.page-header__subnav',
  subNavListItem: '.page-header__link--has-subnav',
  mobilePanelTrigger: 'data-mobile-panel-trigger',
  mobilePanelContent: 'data-mobile-panel-content',
  dropDownBackground: '.page-header__dropdown-background',
  activeClass: 'active',
  hideClass: 'hide',
};

export default class NavigationHeader extends Component {
  static selector = '[data-comp-navigationheader]';
  #navTabs

  // Only set this to true once the hamburger icon has been clicked the first
  // time and the mobile panel offset from the top has been calculated
  #mobilePanelOffsetSet = false;

  #state = {
    activePanel: '',
    mobileNavOpen: false,
    desktopSubNavOpen: false,
  }

  constructor (el, options = DEFAULTS) {
    super(el, options);
    this.el = el;
    Object.assign(this.options = {}, DEFAULTS, options);
    this.subNavListItem = this.$container.querySelector(this.options.subNavListItem);
    this.subNav = this.$container.querySelector(this.options.subNav);
    this.mobileNavTrigger = this.$container.querySelector(`[${this.options.mobileNavTrigger}]`);
    this.mobileContainer = this.$container.querySelector(`.${this.options.mobileContainer}`);
    this.mobileTop = this.$container.querySelector('.page-header__mobile-top');
    this.mobileMainPanel = this.$container.querySelector(this.options.mobileMainPanel);
    this.backgroundOverlay = document.querySelector('.page-header__overlay');
    this.dropDownBackground = document.querySelector('.page-header__dropdown-background');
    this.init();
  }

  init () {
    windowMatchMedia['break-3'].addListener(() => {
      this.navSetup();
    });
    this.navSetup();
  }

  navSetup () {
    const isMobile = !windowMatchMedia['break-3'].matches;

    this.clearListeners();
    this.clearStyles();
    if (isMobile) {
      this.initMobileNav();
    } else {
      this.initDesktopNav();
    }
  }

  initDesktopNav() {
    this.addDesktopSubNavListener();
  }

  addDesktopSubNavListener() {
    this.desktopSubNavListener = this.toggleDesktopSubNavMenu.bind(this);
    this.subNavListItem.addEventListener('click', this.desktopSubNavListener);
    this.subNavMenuHeightResizeListener = this.addSubNavMenuHeight.bind(this);
    this.subNavMenuHeightResizeListenerTimeout = this.addSubNavMenuHeightTimeout.bind(this);
  }

  removeDesktopSubNavListener() {
    this.subNavListItem.removeEventListener('click', this.desktopSubNavListener);
  }

  addSubNavMenuHeightResizeListener() {
    window.addEventListener('resize', this.subNavMenuHeightResizeListener);
    window.addEventListener('resize', this.subNavMenuHeightResizeListenerTimeout);
  }

  removeSubNavMenuHeightResizeListener() {
    window.removeEventListener('resize', this.subNavMenuHeightResizeListener);
    window.removeEventListener('resize', this.subNavMenuHeightResizeListenerTimeout);
  }

  toggleDesktopSubNavMenu() {
    if (!this.#state.desktopSubNavOpen) {
      this.el.classList.add('page-header--active');
      this.#state.desktopSubNavOpen = true;
      this.addOverlayListener();
      this.addSubNavMenuHeight();
      this.addSubNavMenuHeightResizeListener();
    } else {
      this.el.classList.remove('page-header--active');
      this.#state.desktopSubNavOpen = false;
      this.removeSubNavMenuHeight();
      this.removeSubNavMenuHeightResizeListener();
    }
    this.toggleBodyStyle();
  }

  initMobileNav() {
    this.addMobileNavListener();
  }

  addMobileNavListener () {
    this.removeMobileNavHandler = this.handleMobileNavClick.bind(this);

    this.mobileNavTrigger.addEventListener('click', this.removeMobileNavHandler);
    this.mobileContainer.addEventListener('click', this.removeMobileNavHandler);
  }

  removeMobileNavTriggerListener () {
    this.mobileNavTrigger.removeEventListener('click', this.removeMobileNavHandler);
    this.mobileContainer.removeEventListener('click', this.removeMobileNavHandler);
  }

  addMobileScrollListener () {
    this.removeScrollHandler = this.handleMobileNavScroll.bind(this);

    window.addEventListener('scroll', this.removeScrollHandler);
  }

  removeMobileScrollListener () {
    window.removeEventListener('scroll', this.removeScrollHandler);
  }

  addOverlayListener() {
    this.backgroundOverlay.addEventListener('click', this.overlayListener);
  }

  addSubNavMenuHeight() {
    requestAnimationFrame(() => {
      const headerNavBarHeight = this.$container.offsetHeight;
      const subNavigationMenuHeight = this.subNav.offsetHeight;
      const dropDownBackgroundHeight = headerNavBarHeight + subNavigationMenuHeight;
      this.dropDownBackground.style.clipPath = `inset(0 0 calc(100vh - ${dropDownBackgroundHeight}px) 0)`;
    });
  }

  addSubNavMenuHeightTimeout() {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.addSubNavMenuHeight();
    }, 500);
  }

  removeSubNavMenuHeight() {
    this.dropDownBackground.style.clipPath = 'inset(0 0 100vh 0)';
  }

  overlayListener = () => {
    this.clearStyles();
  }

  clearListeners () {
    this.removeMobileNavTriggerListener();
    this.removeMobileScrollListener();
    this.removeDesktopSubNavListener();
    this.mobileContainer.style.top = null;
    this.#navTabs = null;
  }

  clearStyles() {
    if (this.el.classList.contains(this.options.activeClass)) {
      this.el.classList.remove(this.options.activeClass);
    }

    if (this.#state.activePanel) {
      this.#state.activePanel.classList.remove(this.options.activeClass);
    }

    this.el.classList.remove(this.options.mobileNavActiveClass);
    this.removeSubNavMenuHeight();
    this.#state.mobileNavOpen = false;
    this.#state.desktopSubNavOpen = false;
    this.toggleBodyStyle();
    this.removeSubNavMenuHeightResizeListener();
  }

  offsetMobileContainer() {
    const mobilePanelOffset = this.mobileTop.offsetHeight;

    this.mobileContainer.style.top = `${mobilePanelOffset}px`;
    this.#mobilePanelOffsetSet = true;
  }

  handleMobileNavClick(event) {
    const isMobileContainer = event.target.classList.contains(this.options.mobileContainer);
    const isMobileCloseTrigger = event.target.hasAttribute(this.options.mobileNavTrigger)
      || event.target.parentNode.hasAttribute(this.options.mobileNavTrigger);
    const isMobileNestedItem = event.target.hasAttribute(this.options.mobilePanelTrigger);

    if (isMobileNestedItem) {
      event.preventDefault();
      this.toggleMobilePanel(event.target);
      return;
    }

    if (!this.#mobilePanelOffsetSet) {
      this.offsetMobileContainer();
    }

    // Check if the nav is open and if the user is clicking to close the nav
    if (this.#state.mobileNavOpen && (isMobileContainer || isMobileCloseTrigger)) {
      this.toggleMobileNav();
    } else if (!this.#state.mobileNavOpen) {
      this.handleMobileNavOpen();
    }
  }

  handleMobileNavScroll() {
    if (!window.pageYOffset) {
      window.requestAnimationFrame(() => {
        this.toggleMobileNav();
        this.removeMobileScrollListener();
      });
    }
  }

  handleMobileNavOpen() {
    if (!window.pageYOffset) {
      this.toggleMobileNav();
    } else {
      this.addMobileScrollListener();
      window.scroll(0, 0);
    }
  }

  toggleMobileNav() {
    this.#state.mobileNavOpen = !this.#state.mobileNavOpen;
    this.el.classList.toggle(this.options.mobileNavActiveClass);
    this.addOverlayListener();

    // Toggle the first panel's content if the mobile nav is being opened
    if (this.#state.mobileNavOpen) {
      this.toggleBodyStyle();
      this.mobileMainPanel.classList
        .add(this.options.activeClass);
      this.#state.activePanel = this.mobileMainPanel;
    // Else reset the state of the mobile nav
    } else {
      this.clearStyles();
      this.mobileMainPanel.classList
        .remove(this.options.activeClass);
      this.#state.activePanel = '';
    }
  }

  toggleMobilePanel(panelTrigger) {
    const activePanelId = panelTrigger.getAttribute(this.options.mobilePanelTrigger);

    this.#state.activePanel.classList.remove(this.options.activeClass);
    panelTrigger.classList.remove(this.options.activeClass);
    this.#state.activePanel = this.el.querySelector(`[${this.options.mobilePanelContent}="${activePanelId}"]`);
    this.#state.activePanel.classList.add(this.options.activeClass);
    this.#state.activePanel.classList.remove(this.options.hideClass);
  }

  toggleBodyStyle() {
    if (this.#state.mobileNavOpen || this.#state.desktopSubNavOpen) {
      Object.assign(document.body.style, {
        overflow: 'hidden',
        position: 'fixed',
        height: '100%',
        width: '100%',
      });
    } else {
      Object.assign(document.body.style, {
        overflow: null,
        position: null,
        height: null,
        width: null,
      });
    }
  }
}
