import Component from '@Component';
import runInView from '@Core/utility/runInView';

export default class InViewAni extends Component {
  static selector = '[data-ani]'

  constructor(animatedEl) {
    super(animatedEl);
    runInView(this.$container, () => this.animate(), 1);
    this.$container.dataset.aniState = 'paused';
  }

  animate() {
    this.boundOnAnimationEnd = (e) => this.onAnimationEnd(e);
    this.$container.addEventListener('animationend', this.boundOnAnimationEnd);
    this.start();
  }

  onAnimationEnd(e) {
    if (e.target !== this.$container) return;
    this.$container.removeEventListener('animationend', this.boundOnAnimationEnd);
    this.$container.classList.remove('ani-animate');
    this.$container.dataset.aniState = 'done';
  }

  start() {
    this.$container.dataset.aniState = 'running';
    this.$container.classList.add('ani-animate');
  }
}
