import Component from '@Component';

const DEFAULTS = {
  marker: 'section-subnavigation__marker',
  subNavLinks: 'section-subnavigation__link',
  component: 'component',
  currentLink: 'current-link',
  cta: 'section-subnavigation__cta',
  ctaActive: 'section-subnavigation__cta--active',
};

export default class Subnavigation extends Component {
  static selector = '[data-comp-subnavigation]';

  constructor(el, options = DEFAULTS) {
    super(el, options);
    this.el = el;
    Object.assign(this.options = {}, DEFAULTS, options);
    this.marker = this.$container.querySelector(`.${this.options.marker}`);
    this.subNavLinks = this.$container.querySelectorAll(`.${this.options.subNavLinks}`);
    this.components = document.querySelectorAll(`.${this.options.component}`);
    this.subnavigationHeight = this.$container.offsetHeight;
    this.parentContainer = this.$container.parentElement;
    this.cta = this.$container.querySelector(`.${this.options.cta}`);

    this.init();
  }

  init() {
    this.addScrollListener();
    this.addResizeListener();
    this.addSubNavLinkListeners();
    this.addInitialMarkerStyles();
    this.addPageHeroLinkListener();
  }

  addScrollListener() {
    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.determineSubNavBoundaries();
      });
    });
  }

  determineSubNavBoundaries() {
    const firstComponent = document.querySelector(this.getFirstSubNavLink().hash);
    const firstComponentTop = firstComponent.offsetTop - this.parentContainer.offsetTop;

    const lastComponent = document.querySelector(this.getLastSubNavLink().hash);
    const lastComponentTop = lastComponent.offsetTop - this.parentContainer.offsetTop;

    const scrollPosition = this.getParentScrollTop() + this.subnavigationHeight;

    const halfViewHeight = (window.innerHeight / 2);

    if (scrollPosition - this.subnavigationHeight + halfViewHeight > firstComponentTop
      && scrollPosition < lastComponentTop) {
      this.determineCurrentLink();
      this.moveMarker();
      this.showCTA();
    } else if (scrollPosition >= lastComponentTop) {
      this.addCurrentLinkToLastSubNavLink();
    } else if (scrollPosition < firstComponentTop) {
      this.addInitialMarkerStyles();
      this.hideCTA();
    }
  }

  determineCurrentLink() {
    const fromTop = this.getParentScrollTop() + this.subnavigationHeight;
    this.subNavLinks.forEach(link => {
      const section = document.querySelector(link.hash);
      const halfViewHeight = (window.innerHeight / 2);
      if (
        section.offsetTop <= fromTop + halfViewHeight
        && section.offsetTop + section.offsetHeight > fromTop + halfViewHeight
      ) {
        const currentLinks = document.querySelectorAll(`.${this.options.currentLink}`);
        // For overlapping elements, only allow the last one to have the currentLink
        if (currentLinks.length > 1) {
          for (let i = 0; i < currentLinks.length; i += 1) {
            if (i === 0) {
              currentLinks[i].classList.remove(this.options.currentLink);
            } else if (i === 1) {
              currentLinks[i].classList.add(this.options.currentLink);
              this.determineHorizontalScroll();
            } else {
              const t = i - 1;
              currentLinks[t].classList.remove(this.options.currentLink);
              currentLinks[i].classList.add(this.options.currentLink);
              this.determineHorizontalScroll();
            }
          }
        } else if (!link.classList.contains(this.options.currentLink)) {
          if (this.getCurrentLink()) {
            this.getCurrentLink().classList.remove(this.options.currentLink);
          }
          link.classList.add(this.options.currentLink);
          this.determineHorizontalScroll();
        }
      }
    });
  }

  determineHorizontalScroll() {
    let counter = 0;
    this.subNavLinks.forEach(link => {
      if (link.classList.contains(this.options.currentLink)) {
        const linkWidth = link.offsetWidth;
        const linkLeftPosition = link.offsetLeft;
        const windowWidth = window.innerWidth;
        const halfWindowWidth = windowWidth / 2;

        if (linkLeftPosition > halfWindowWidth) {
          const linkCenter = (windowWidth - linkWidth) / 2;
          const scrollAmount = linkLeftPosition - linkCenter;
          this.$container.scroll({
            left: scrollAmount,
            behavior: 'smooth',
          });
        } else if (counter === 0 || counter === 1) {
          this.$container.scrollTo(0, 0);
        }
      }
      counter += 1;
    });
  }

  moveMarker() {
    const currentLinkLeft = this.getCurrentLink().offsetLeft;
    const currentLinkWidth = this.getCurrentLink().offsetWidth;
    this.marker.style.left = `${currentLinkLeft}px`;
    this.marker.style.width = `${currentLinkWidth}px`;
  }

  showCTA() {
    this.cta.style.visibility = 'visible';
    this.cta.classList.add(this.options.ctaActive);
  }

  hideCTA() {
    if (this.cta.classList.contains(this.options.ctaActive)) {
      this.cta.style.visibility = 'hidden';
      this.cta.classList.remove(this.options.ctaActive);
    }
  }

  addSubNavLinkListeners() {
    this.subNavLinks.forEach(link => {
      link.addEventListener('click', () => {
        window.setTimeout(() => this.hashChangeScroll(), 0);
      });
    });
    // If there is a hash already in the URL when page is loaded, scroll to that section
    window.setTimeout(() => this.hashChangeScroll(), 500);
  }

  addPageHeroLinkListener() {
    const pageHeroLink = document.querySelector('.section-page-hero a[href^="#"]');
    if (pageHeroLink) {
      let isSubnavigationSection = false;
      this.subNavLinks.forEach(link => {
        if (link.hash === pageHeroLink.hash) {
          isSubnavigationSection = true;
        }
      });
      if (isSubnavigationSection) {
        pageHeroLink.addEventListener('click', () => {
          window.setTimeout(() => this.hashChangeScroll(), 0);
        });
      }
    }
  }

  hashChangeScroll() {
    if (window.location.hash.length !== 0) {
      const link = window.location.hash;
      const section = document.querySelector(link);
      const sectionTop = section.offsetTop;

      // If the section being scrolled to is above the subnavigation
      if (section.offsetTop < this.$container.offsetTop) {
        window.scrollTo(window.scrollX, sectionTop + this.parentContainer.offsetTop);
      } else { // If the section being scrolled to is below the subnavigation
        // Minus an extra 50 in the scrollTo to make sure the marker changes
        window.scrollTo(window.scrollX, sectionTop - this.subnavigationHeight
          + this.parentContainer.offsetTop - 50);
      }
    }
  }

  addCurrentLinkToLastSubNavLink() {
    this.getCurrentLink().classList.remove(this.options.currentLink);
    const lastSubNavLink = this.subNavLinks[this.subNavLinks.length - 1];
    lastSubNavLink.classList.add(this.options.currentLink);
    this.moveMarker();
  }

  addResizeListener() {
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => {
        this.determineSubNavBoundaries();
      });
    });
  }

  addInitialMarkerStyles() {
    if (this.getCurrentLink()) {
      this.getCurrentLink().classList.remove(this.options.currentLink);
    }
    const firstSubNavLinkLeft = this.getFirstSubNavLink().offsetLeft;
    this.marker.style.left = `${firstSubNavLinkLeft}px`;
    this.marker.style.width = 0;
  }

  // Helpers
  getCurrentLink() {
    return document.querySelector(`.${this.options.currentLink}`);
  }

  getFirstSubNavLink() {
    return this.subNavLinks[0];
  }

  getLastSubNavLink() {
    return this.subNavLinks[this.subNavLinks.length - 1];
  }

  getParentScrollTop() {
    return window.scrollY - this.parentContainer.offsetTop;
  }
}
