import { guid } from './util';

/**
 * Binds a component to the DOM
 * @param {HTMLElement} DOMElement
 * @param {Component} Component
 */
export function bindComponent(entry) {
  return Object.assign(entry, {
    instance: new entry.Component(entry.element),
    loaded: true,
  });
}

export function createRegistryEntry(element, Component) {
  return {
    id: guid(),
    loaded: false,
    element,
    Component,
  };
}
