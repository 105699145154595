import Component from '@Component';

export default class ExpandText extends Component {
  static selector = '[data-comp-max-lines]';
  static loaderPriority = 'in-view'

  constructor(container) {
    super(container);
    this.lastChild = this.$container.children[this.$container.children.length - 1];
    this.expandUrl = this.$container.dataset.jsMaxLinesUrl;
    this.triggerLabel = this.$container.dataset.jsMaxLinesTriggerLabel || '+ Read More';

    // Makes the "...more" link into a direct link to more content.
    this.linkMode = this.$container.dataset.jsMaxLinesLinkMode;

    if (this.$container.dataset.jsExpandTextLines) {
      this.options.lines = parseInt(this.$container.dataset.jsExpandTextLines, 10);
    }

    this.$state.collapsed = true;

    if (typeof this.lastChild === 'object') {
      // if the last child is an object (i.e. HTMLElement),
      // observe it. If it gets obscured by its parent container,
      // initialize this toggle button.
      this.createIntersectionObsv().observe(this.lastChild);
      console.log('createIntersectionObsv');
    } else {
      // if the last child is a textNode, just initialize it.
      // We can't get the height or observe those nodes.
      this.init();
    }
  }

  init() {
    console.log('init');
    // Create and append a toggle button to the end of the container.
    this.$container.insertAdjacentHTML('beforeend', this.createToggleButton(this.triggerLabel, 'text-link max-lines__toggle'));
    this.triggerButton = this.$container.querySelector('[data-comp-max-lines-toggle]');
    // Set up event listeners
    this.events();

    // Remove intersection observer
    // We've decided we need to show the toggle,
    // so we can clean up our observer.
    this.removeIntersectionObsv();
  }

  hideTriggerButton() {
    if (this.triggerButton) {
      this.triggerButton.style.display = 'none';
    }
  }

  events() {
    this.triggerButton.setAttribute('aria-role', 'button');
    this.triggerButton.addEventListener('click', this.clickEvent = this.expandOrCollapse.bind(this));
  }

  expandOrCollapse(clickEvent) {
    clickEvent.preventDefault();

    if (!this.$state.collapsed) {
      this.collapseTextContainer();
    } else if (this.$state.collapsed) {
      this.expandTextContainer();
    }
  }

  expandTextContainer() {
    this.$state.collapsed = false;
    this.$container.classList.add('max-lines__expanded');

    if (this.$container.dataset.jsMaxLinesExpandedMax) {
      this.switchTriggerToLink();
      return;
    }

    if (this.triggerButton) this.triggerButton.innerHTML = '- Read Less';
  }

  collapseTextContainer() {
    this.$state.collapsed = true;
    this.$container.classList.remove('max-lines__expanded');

    if (this.triggerButton) this.triggerButton.innerHTML = this.triggerLabel;
  }

  switchTriggerToLink() {
    this.triggerButton.innerHTML = 'Read All';
    this.triggerButton.href = this.expandUrl;
    this.triggerButton.removeEventListener('click', this.clickEvent);
  }

  createIntersectionObsv() {
    return this.textObserver = new IntersectionObserver((records) => {
      records.forEach(record => {
        // eslint-disable-next-line no-unused-expressions
        record.intersectionRatio < 1 ? this.init() : this.hideTriggerButton();
      });
    }, {
      threshold: [1, 0],
      root: this.$container,
    });
  }

  removeIntersectionObsv() {
    if (typeof this.lastChild === 'object') {
      this.textObserver.unobserve(this.lastChild);
    }
    this.textObserver = undefined;
  }

  createToggleButton(label, className) {
    return `<button data-comp-max-lines-toggle id="trigger_${this.$id}" class="${className}">${label}</button>`;
  }
}
