import { ComponentLoader } from '@Component';
import CategorySelect from '@Core/components/categorySelect';
import InViewStyle from '@Core/components/inViewStyle';
import InViewSrc from '@Core/components/inViewSrc';
import InViewAni from '@Core/components/inViewAni';
import HeaderNavigation from '@Core/components/headerNavigation';
import HeroSlider from '@Core/components/heroSlider';
import Faqs from '@Core/components/faqs';
import Modal from '@Core/components/microModalAdpt';
import Subnavigation from '@Core/components/subnavigation';
import MaxLines from '@Core/components/maxLines';
import TrackEvent from '@Core/components/trackEvent';
import NewsletterForm from '@Core/components/newsletterForm';
import {
  SectionGeneralSlider, SectionGeneralVehicleSlider, SectionPhotoSlider, SectionTestimonialSlider,
} from '@Core/components/sliders';

window.globalComponents = new ComponentLoader(document, [
  CategorySelect,
  InViewStyle,
  InViewSrc,
  InViewAni,
  HeaderNavigation,
  HeroSlider,
  Faqs,
  Modal,
  Subnavigation,
  MaxLines,
  SectionGeneralSlider,
  SectionGeneralVehicleSlider,
  SectionPhotoSlider,
  SectionTestimonialSlider,
  TrackEvent,
  NewsletterForm,
]);
