import Component from '@Component';

const DEFAULTS = {
  activeClass: 'accordion-nav__heading--active',
  triggerSelector: '[data-comp-accordion-trigger]',
  contentSelector: '[data-comp-accordion-content]',
  defaultMaxHeight: '0',
};

export default class Accordion extends Component {
  static selector = '[data-comp-accordion]';

  constructor(containerEl, options = DEFAULTS) {
    super(containerEl, options);
    Object.assign(this.$options, DEFAULTS, options);
    this.triggers = this.$container.querySelectorAll(this.$options.triggerSelector);
    this.contents = this.$container.querySelectorAll(this.$options.contentSelector);
    this.collapseAllContent();
    this.addClickEventListeners();
  }

  /**
   * Show a single Accordion content, and hide all others.
   * @param  {number} index The index of the content to show.
   */
  expandContent(index) {
    this.expandAccordion(this.triggers[index]);
  }

  /**
   * Collapses all Accordion contents, and remove active classes from triggers
   * @return {void}
   */
  collapseAllContent() {
    this.triggers.forEach((trigger) => {
      this.collapseContent(trigger);
    });
  }

  collapseContent(triggerElement) {
    const trigger = triggerElement;
    trigger.classList.remove(this.$options.activeClass);
    trigger.nextElementSibling.style.maxHeight = `${this.$options.defaultMaxHeight}px`;
  }

  /**
   * Wire up all click events
   * @return {void}
   */
  addClickEventListeners() {
    this.triggers.forEach((trigger) => {
      this.removeTriggerHandler = this.triggerClickEventHandler.bind(this, trigger);

      trigger.addEventListener('click', this.removeTriggerHandler);
    });
  }

  /**
   * Clear all click event listeners
   * @return {void}
   */
  clearAllClickEventListeners() {
    this.triggers.forEach((trigger) => {
      trigger.removeEventListener('click', this.removeTriggerHandler);
    });
  }

  /**
   * Handle trigger click event
   * @param {HTML element} trigger The Accordion trigger element
   * @return {void}
   */
  triggerClickEventHandler(trigger) {
    const close = trigger.classList.contains(this.$options.activeClass);
    this.collapseAllContent();
    if (!close) this.expandAccordion(trigger);
  }

  /**
   * Given a trigger element, expand an Accordion content and hide all others.
   * @param  {HTMLElement} trigger The Accordion trigger element
   * @return {void}
   */
  expandAccordion(trigger) {
    applyExpandStyleToContent(trigger);
    trigger.classList.add(this.$options.activeClass);
  }
}

/**
 * Apply the styles required to expand an Accordion trigger.
 * @param  {HTMLElement} trigger The Accordion trigger element
 * @return {void}
 */
function applyExpandStyleToContent(trigger) {
  const content = trigger.nextElementSibling;
  // Count up the height of all WYSIWYG child elements (p, ul, etc.)
  const totalHeight = [...content.children].map(el => {
    const style = window.getComputedStyle(el);
    // eslint-disable-next-line radix
    const vertMargin = parseInt(style['margin-top']) + parseInt(style['margin-bottom']);
    // eslint-disable-next-line radix
    const vertPadding = parseInt(style['padding-top']) + parseInt(style['padding-bottom']);
    return el.offsetHeight + vertMargin + vertPadding;
  }).reduce((a, b) => a + b);
  // total height of all children
  content.style.maxHeight = `${totalHeight}px`;
}
