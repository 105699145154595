import Glide from '@glidejs/glide';
import { removeClassFromElements } from '@Core/utility';

export default class GlideSlider {
  constructor(sliderEl, glideOptions,
    selectors = {
      slideSelector: '[data-slider-slide]',
      leftArrowSelector: '[data-slider-arrow-left]',
      rightArrowSelector: '[data-slider-arrow-right]',
      slideIndicatorSelector: '[data-slider-nav-indicator]',
      slideIndicatorActiveClass: 'nav-btn--active',
      arrowsContainerSelector: '[data-slider-arrows]',
    }) {
    this.slider = new Glide(sliderEl, glideOptions);
    this.sliderEl = sliderEl;
    this.sliderTrack = this.sliderEl.querySelector('[data-glide-el="track"]');
    this.overlaySelector = '--slider-overlay';
    this.slidesEl = this.sliderEl.querySelectorAll(selectors.slideSelector);
    this.numberOfSlides = this.slidesEl.length;

    // Arrow navigation is optional
    if (this.sliderEl.querySelector(selectors.leftArrowSelector)
      && this.sliderEl.querySelector(selectors.rightArrowSelector)) {
      this.arrowsContainer = this.sliderEl.querySelector(selectors.arrowsContainerSelector);
      this.leftArrowBtnEl = this.sliderEl.querySelector(selectors.leftArrowSelector);
      this.rightArrowBtnEl = this.sliderEl.querySelector(selectors.rightArrowSelector);
    }

    // Nav indicators are optional
    if (this.sliderEl.querySelector(selectors.slideIndicatorSelector)) {
      this.slideIndicatorSelector = selectors.slideIndicatorSelector;
      this.slideIndicatorSelectorWithoutBrackets = this.slideIndicatorSelector
        .substring(1, this.slideIndicatorSelector.length - 1);
      this.slideIndicatorActiveClass = selectors.slideIndicatorActiveClass;
      this.slideIndicatorsEl = this.sliderEl.querySelectorAll(this.slideIndicatorSelector);
    }
  }

  /*
   * Used to hook into glide API
   * @return {Glide}
   */
  getGlideSlider() {
    return this.slider;
  }

  /*
   * @return {NodeList}
   */
  getSliderElement() {
    return this.sliderEl;
  }

  /*
   * @return {int}
   */
  getSliderWidth() {
    return this.sliderEl.offsetWidth;
  }

  /*
   * @return {int}
   */
  getNumberOfSlides() {
    return this.slidesEl.length;
  }

  /*
   * @return {NodeList}
   */
  getSlidesElements() {
    return this.slidesEl;
  }

  /*
   * @param {int} width
   * @return {void}
   */
  adjustSlideWidth(width) {
    /* eslint-disable no-param-reassign */
    this.slidesEl.forEach(slide => {
      slide.style.width = width;
    });
  }

  /*
   * Enables and disables slider in desktop view based on given breakpoint
   * @param {int} breakpoint
   * @return {void}
   */
  disableSliderInDesktopView(breakpoint) {
    if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
      this.slider.disable();
    } else {
      this.slider.enable();
    }
  }

  /*
   * Handles when to hide and show the left and right arrow buttons
   * @return {void}
   */
  handleArrowButtonVisibility() {
    if (this.slider.index === 0) {
      this.leftArrowBtnEl.style.display = 'none';
    } else {
      this.leftArrowBtnEl.style.display = 'flex';
    }

    if (this.isOnLastSlideOrIsPastLastSlide()) {
      this.rightArrowBtnEl.style.display = 'none';
      this.hideOverlay();
    } else {
      this.rightArrowBtnEl.style.display = 'flex';
      this.showOverlay();
    }
  }

  /*
   * Handles when to hide and show the left and right arrow buttons
   * @return {void}
   */
  handleHeadingVisibility(headingEl) {
    if (this.slider.index >= 1) {
      headingEl.style.opacity = '0';
    } else {
      headingEl.style.opacity = '';
    }
  }

  /*
   * Removes overlay styling
   * @return {void}
   */
  hideOverlay() {
    this.sliderTrack.style.setProperty(this.overlaySelector, 'none');
  }

  /*
   * Adds overlay styling
   * @return {void}
   */
  showOverlay() {
    const overlay = getComputedStyle(document.body)
      .getPropertyValue(this.overlaySelector);

    this.sliderTrack.style.setProperty(this.overlaySelector, overlay);
  }

  /*
   * Handles when to disable the left and right arrow buttons
   * @return {void}
   */
  handleArrowButtonState() {
    this.leftArrowBtnEl.disabled = this.slider.index === 0;
    this.rightArrowBtnEl.disabled = this.slider.index === this.numberOfSlides - 1;
  }

  hasArrowButtons() {
    return this.arrowsContainer;
  }

  /*
   * Handles styling of the slide indicator's active state
   * @return {void}
   */
  handleSlideIndicatorActiveState() {
    for (let i = 0; i < this.numberOfSlides; i += 1) {
      if (this.slider.index === i && this.slideIndicatorsEl) {
        removeClassFromElements(this.slideIndicatorsEl, this.slideIndicatorActiveClass);
        this.sliderEl.querySelector(`[${this.slideIndicatorSelectorWithoutBrackets}="${i}"]`)
          .classList.add(this.slideIndicatorActiveClass);
      }
    }
  }

  hasSlideIndicator() {
    return this.slideIndicatorsEl;
  }

  /*
   * Helper function that checks if we're currently on the last slide or if we're past it
   * @return {bool}
   */
  isOnLastSlideOrIsPastLastSlide() {
    return this.slider.index >= (this.numberOfSlides - 1)
      || this.slider.index >= (this.numberOfSlides - this.slider.settings.perView);
  }
}
