import Component from '@Component';
import MicroModal from 'micromodal';

const openClass = 'modal--open';

export default class MicroModalAdaptor extends Component {
  static selector = '[data-comp-modal-trigger]'

  constructor(...args) {
    super(...args);
    this.modalId = `${this.$container.dataset.compModalTrigger}-modal`;
    this.modalContainer = document.getElementById(this.modalId);

    if (!this.modalContainer) {
      console.warn(`[MicroModalAdaptor] Modal with id '${this.modalId} is not found.`);
      return;
    }

    this.modalController = MicroModal;
    this.modalCloseTrigger = this.modalContainer.querySelector('[data-comp-modal-close-trigger]');
    this.modalOverlay = this.modalContainer.querySelector('[data-micromodal-close]');
    this.boundApplyHiddenStyles = () => this.applyHiddenStyles();
    this.events();
  }

  show() {
    this.setEventDatasetAttributes();
    this.modalController.show(this.modalId, {
      awaitCloseAnimation: true,
      disableScroll: true,
      openClass,
    });
  }

  close() {
    this.pauseVideos();
    this.removeEventDatasetAttributes();
    this.modalController.close(this.modalId);
    this.modalContainer.addEventListener('animationend', this.boundApplyHiddenStyles);
  }

  setEventDatasetAttributes() {
    const { dataset } = this.$container;
    this.setTrackedElementAttributes(dataset.eventCategory, dataset.eventLabel);
    this.setModalAttributes(
      dataset.eventCategory, dataset.eventAction, dataset.eventLabel,
    );
  }

  removeEventDatasetAttributes() {
    this.setTrackedElementAttributes();
    this.setModalAttributes();
  }

  setTrackedElementAttributes(eventCategory = '', eventLabel = '') {
    this.modalEventTrackedElements = this.modalContainer.querySelectorAll('[data-comp-track-event]');
    this.modalEventTrackedElements.forEach(el => {
      const { dataset } = el;
      dataset.eventCategory = eventCategory;
      dataset.eventLabel = eventLabel;
    });
  }

  setModalAttributes(eventCategory = '', eventAction = '', eventLabel = '') {
    this.modalContainer.setAttribute('data-event-category', eventCategory);
    this.modalContainer.setAttribute('data-event-action', eventAction);
    this.modalContainer.setAttribute('data-event-label', eventLabel);
  }

  applyHiddenStyles() {
    this.modalContainer.removeEventListener('animationend', this.boundApplyHiddenStyles);
    this.modalContainer.classList.remove(openClass);
  }

  events() {
    this.$container.addEventListener('click', () => this.show());
    this.modalCloseTrigger.addEventListener('click', () => this.close());
    this.modalOverlay.addEventListener('click', () => this.pauseVideos());
  }

  pauseVideos() {
    // Resetting video src stops video from playing
    // ^ easier than working through the YouTube API
    const iframeElement = this.modalContainer.querySelector('iframe');
    if (iframeElement) {
      const iframeElementSrc = iframeElement.getAttribute('src');
      iframeElement.setAttribute('src', iframeElementSrc);
    }
  }
}
