import Component from '@Component';
import trackEvent from '@Core/tracking';

export default class NewsletterForm extends Component {
  static selector = '[data-comp-newsletter-form]';

  constructor(containerEl) {
    super(containerEl);
    this.successMessage = 'mc_success_msg';
    this.trackNewsletterSubscriptionEvent();
  }

  // When a user submits the form created by the Mailchimp plugin, the page refreshes.
  // If the email was accepted, a success message appears.
  // If the success message is present, the below function will track the event to Google Analytics
  trackNewsletterSubscriptionEvent() {
    if (this.$container.querySelector(`.${this.successMessage}`)) {
      const { dataset } = this.$container;
      trackEvent(dataset.eventCategory, 'On Submit', dataset.eventLabel);
    }
  }
}
