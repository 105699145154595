import Component from '@Component';

const DEFAULTS = {
  heroSlide: 'hero-slide',
  slideControls: 'hero-slider__slide-controls',
  nextSlideArrow: 'previous-next-buttons__next',
  previousSlideArrow: 'previous-next-buttons__previous',
  slideCount: 'hero-slider__slide-count',
};

export default class heroSlider extends Component {
  static selector = '[data-comp-heroslider]';

  #state = {
    previousSlide: null,
    slide: 0,
    transitioning: false,
    autoRotate: true,
  }

  constructor(el, options = DEFAULTS) {
    super(el, options);
    this.el = el;
    Object.assign(this.options = {}, DEFAULTS, options);
    this.heroSlides = this.$container.querySelectorAll(`.${this.options.heroSlide}`);
    this.slideControls = this.$container.querySelector(`.${this.options.slideControls}`);
    this.slideCount = this.$container.querySelector(`.${this.options.slideCount}`);
    this.nextSlideArrow = this.$container.querySelector(`.${this.options.nextSlideArrow}`);
    this.previousSlideArrow = this.$container.querySelector(`.${this.options.previousSlideArrow}`);
    this.autoRotateDuration = 4000;
    this.autoRotateTimer = setTimeout(() => {
      this.showNextSlide();
    }, this.autoRotateDuration);

    this.boundAnimateNextSlide = e => this.animateNextSlide(e);
    this.init();
  }

  init() {
    this.initialFadeIn();
    this.addPrevNextSlideButtonListeners();
    this.updateSlideCount();
  }

  initialFadeIn() {
    // Fade in animation the first slide on page load
    this.heroSlides[0].classList.add('hero-slide--active');
  }

  addPrevNextSlideButtonListeners() {
    // Next Slide Button Listener
    // Todo: Combine the two addEventListeners into one
    this.nextSlideArrow.addEventListener('click', () => {
      this.disableAutoRotate();
    });
    this.nextSlideArrowListener = this.showNextSlide.bind(this);
    this.nextSlideArrow.addEventListener('click', this.nextSlideArrowListener);
    // Previous Slide Button Listener
    // Todo: Combine the two addEventListeners into one
    this.previousSlideArrow.addEventListener('click', () => {
      this.disableAutoRotate();
    });
    this.previousSlideArrowListener = this.showPreviousSlide.bind(this);
    this.previousSlideArrow.addEventListener('click', this.previousSlideArrowListener);
  }

  updateSlideCount() {
    const spans = this.slideCount.querySelectorAll('span');
    const currentSlideSpan = spans[0];
    const totalSlidesSpan = spans[2];

    let totalSlides = this.heroSlides.length;
    // Add the zero before the number if less than 10 for stylistic effect
    if (totalSlides < 10) {
      totalSlides = `0${totalSlides}`;
    }
    totalSlidesSpan.innerHTML = totalSlides;

    let currentSlide = this.#state.slide + 1;
    // Add the zero before the number if less than 10 for stylistic effect
    if (currentSlide < 10) {
      currentSlide = `0${currentSlide}`;
    }
    currentSlideSpan.innerHTML = currentSlide;
  }

  showNextSlide() {
    this.clearAutoRotateTimer();
    if (this.#state.transitioning) return;
    this.setTransitioningToTrue();
    this.#state.previousSlide = this.#state.slide;
    if (this.#state.slide === (this.heroSlides.length - 1)) {
      this.#state.slide = 0;
    } else {
      this.#state.slide = this.#state.slide + 1;
    }
    this.determineSlideToShow();
    this.updateSlideCount();
  }

  showPreviousSlide() {
    this.clearAutoRotateTimer();
    if (this.#state.transitioning) return;
    this.setTransitioningToTrue();
    this.#state.previousSlide = this.#state.slide;
    if (this.#state.slide === 0) {
      this.#state.slide = this.heroSlides.length - 1;
    } else {
      this.#state.slide = this.#state.slide - 1;
    }
    this.determineSlideToShow();
    this.updateSlideCount();
  }

  setTransitioningToTrue() {
    this.#state.transitioning = true;
  }

  setTransitioningToFalse() {
    this.#state.transitioning = false;
    this.getCurrentSlide().removeEventListener('transitionend', this.setTransitioningToFalseBinded);
  }

  // currentSlide == slide to animate IN
  // previousSlide == slide to animate OUT
  determineSlideToShow() {
    this.getPrevSlide().addEventListener('transitionend', this.boundAnimateNextSlide);
    this.animatePrevSlide();
    this.setAutoRotateTimer();
  }

  setAutoRotateTimer() {
    if (this.#state.autoRotate === false) return;
    this.autoRotateTimer = setTimeout(() => {
      this.showNextSlide();
    }, this.autoRotateDuration);
  }

  clearAutoRotateTimer() {
    clearTimeout(this.autoRotateTimer);
  }

  disableAutoRotate() {
    this.#state.autoRotate = false;
  }

  animateNextSlide(e) {
    const prevSlideEl = this.getPrevSlide();
    if (e.target !== prevSlideEl) return;
    e.stopPropagation();
    prevSlideEl.removeEventListener('transitionend', this.boundAnimateNextSlide);
    fadeInSlide(this.getCurrentSlide());

    this.setTransitioningToFalseBinded = this.setTransitioningToFalse.bind(this);
    this.getCurrentSlide().addEventListener('transitionend', this.setTransitioningToFalseBinded);
  }

  animatePrevSlide() {
    fadeOutSlide(this.getPrevSlide());
  }

  // Helper methods
  getSlide(i) {
    return this.heroSlides[i];
  }

  getCurrentSlide() {
    return this.getSlide(this.#state.slide);
  }

  getPrevSlide() {
    return this.getSlide(this.#state.previousSlide);
  }
}

function fadeInSlide(slideToFadeIn) {
  const slide = slideToFadeIn;
  slide.classList.add('hero-slide--active');
}

function fadeOutSlide(slideToFadeOut) {
  const slide = slideToFadeOut;
  slide.classList.remove('hero-slide--active');
}
