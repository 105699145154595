import Component from '@Component';
import runInView from '@Core/utility/runInView';

export default class inViewStyle extends Component {
  static selector = '[data-style]'

  constructor(iframeEl) {
    super(iframeEl);
    if (!iframeEl) return;
    this.$el = iframeEl;
    runInView(this.$el, () => this.setStyle(), 1);
  }

  setStyle() {
    window.requestAnimationFrame(() => {
      this.$el.style = this.$el.dataset.style;
      this.$el.removeAttribute('data-style');
    });
  }
}
