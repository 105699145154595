import Component from '@Component';
import runInView from '@Core/utility/runInView';

class InViewSrc extends Component {
  static selector = '[data-src]'

  constructor(element) {
    super(element);
    this.boundCleanup = () => this.cleanup();
    this.boundRemoveMaskStyles = () => this.removeMaskStyles();

    runInView(this.$container, () => this.init());
  }

  init() {
    this.$container.addEventListener('load', this.boundRemoveMaskStyles);
    requestAnimationFrame(() => {
      this.$container.src = this.$container.dataset.src;
    });
  }

  removeMaskStyles() {
    this.$container.addEventListener('transitionend', this.boundCleanup);
    this.setOrientation();
    requestAnimationFrame(() => {
      this.$container.style.transition = 'opacity 500ms ease-out';
      this.$container.style.setProperty('--image-opacity', 'initial');
    });
  }

  cleanup() {
    this.$container.removeEventListener('load', this.boundCleanup);
    requestAnimationFrame(() => {
      this.$container.removeAttribute(InViewSrc.selector.replace(/[[\]']+/g, ''));
      this.$container.style.transition = null;
    });
  }

  setOrientation() {
    this.$container.dataset.orientation = this.$container.naturalWidth > this.$container.naturalHeight ? 'landscape' : 'portrait';
  }
}

export default InViewSrc;
