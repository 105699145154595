import Component from '@Component';
import trackEvent from '@Core/tracking';

export default class TrackEvent extends Component {
  static selector = '[data-comp-track-event]'

  constructor(containerEl) {
    super(containerEl);
    this.eventName = 'click';
    this.attachEvent();
  }

  attachEvent() {
    this.$container.addEventListener(this.eventName, () => {
      const {
        eventCategory,
        eventAction,
        eventLabel,
      } = this.$container.dataset;
      trackEvent(eventCategory, eventAction, eventLabel);
    });
  }
}
