import Component from '@Component';
import { breakpoints } from '@Constants';
import GlideSlider from '../base';

const glideOptions = {
  type: 'slider',
  startAt: 0,
  perView: 2,
  peek: 16,
  rewind: false,
  bound: true,
  gap: 16,
  breakpoints: {
    [breakpoints['break-2']]: {
      perView: 1,
    },
  },
};

const selectors = {
  slideSelector: '[data-vehicle-slider="slide"]',
  leftArrowSelector: '[data-vehicle-slider="arrow-left"]',
  rightArrowSelector: '[data-vehicle-slider="arrow-right"]',
  arrowsContainerSelector: '[data-vehicle-slider="arrows"]',
  slideIndicatorSelector: '[data-vehicle-slider="indicator"]',
  heading: '[data-vehicle-slider="heading"]',
  slideIndicatorActiveClass: 'nav-btn--active',
};

export default class SectionGeneralSlider extends Component {
  static selector = '[data-comp-vehicle-slider]';
  constructor(containerEl) {
    super(containerEl);
    this.subNavSlider = new GlideSlider(
      containerEl.querySelector('.glide'),
      glideOptions,
      selectors,
    );

    this.headingEl = this.$container.querySelector(selectors.heading);

    this.subNavSliderGlideInstance = this.subNavSlider.getGlideSlider();

    if (this.subNavSlider.hasArrowButtons()) {
      this.subNavSliderGlideInstance.on(['mount.after', 'run.after'], () => {
        this.subNavSlider.handleArrowButtonVisibility();
        this.subNavSlider.handleHeadingVisibility(this.headingEl);
      });
    }

    this.subNavSliderGlideInstance.mount();
  }
}
