import Component from '@Component';
import Accordion from '@Core/components/accordion';

export default class Faqs extends Component {
  static selector = '[data-comp-faqs]';

  constructor(containerEl, options) {
    super(containerEl, options);

    this.accordionActiveClass = 'section-frequently-asked-questions__question-container--active';
    this.init();
  }

  init() {
    this.accordion = new Accordion(this.$container, {
      activeClass: this.accordionActiveClass,
    });
  }
}
