/**
 * Needs to correspond with breakpoints in _breakpoints.scss
 */
export default {
  'break-0': 0,
  'break-1': 480,
  'break-2': 768,
  'break-3': 1200,
  'break-4': 1400,
};
