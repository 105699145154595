import Component from '@Component';
import GlideSlider from '../base';

// Refer to https://glidejs.com/docs/options/
const glideOptions = {
  type: 'carousel',
  rewind: false,
  gap: 0,
  autoplay: 4000,
};

const selectors = {
  slideSelector: '[data-testimonial-slider="slide"]',
  leftArrowSelector: '[data-testimonial-slider="arrow-left"]',
  rightArrowSelector: '[data-testimonial-slider="arrow-right"]',
};

export default class SectionTestimonialSlider extends Component {
  static selector = '[data-comp-testimonial-slider]';

  constructor(containerEl) {
    super(containerEl);
    this.slider = new GlideSlider(containerEl, glideOptions, selectors);
    this.sliderGlideInstance = this.slider.getGlideSlider();

    this.sliderGlideInstance.on(['mount.after', 'run.after'], () => {
      this.slider.adjustSlideWidth(this.slider.getSliderWidth());
      this.slider.handleSlideIndicatorActiveState();
    });

    this.sliderGlideInstance.mount();
  }
}
