import guid from '@Core/utility/guid';

export default class Component {
  static loaderPriority = 'idle'
  static loaderPriorityDelay = 0

  constructor(element, options = {}, state = {}) {
    this.$id = guid();
    this.$container = element;
    this.$options = { ...options };
    this.$state = { ...state };
  }
}

export ComponentLoader from './loader';
