import Component from '@Component';

export default class CategorySelect extends Component {
  static selector = '[data-comp-category-select]';

  constructor(containerEl, options) {
    super(containerEl, options);
    this.activeCategory = this.$container.getAttribute('data-active-category');
    this.defaultCategory = this.$container.getAttribute('data-default-category');
    this.init();
  }

  init() {
    this.selectActiveOption();
    this.addChangeListener();
  }

  selectActiveOption() {
    this.$container.value = this.activeCategory ? this.activeCategory : this.defaultCategory;
  }

  addChangeListener() {
    this.$container.addEventListener('change', () => {
      document.location.href = this.$container.options[this.$container.selectedIndex].getAttribute('data-url');
    });
  }
}
